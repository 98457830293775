import { PEGGID } from '../actions/action_types';

// Adds or removes pegg id from array
export default function peggIDs(state = [], action) {
  switch (action.type) {
    case PEGGID: {
      const newState = state.slice();
      const index = newState.indexOf(action?.payload?.id);
      if (action?.payload?.action === 'add' && index < 0)
        newState.push(action?.payload?.id);
      else if (action?.payload?.action === 'delete' && index >= 0)
        newState.splice(index, 1);
      return newState;
    }
    default:
      return state;
  }
}
