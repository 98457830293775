import { OPENMODAL, UPDATEMODAL } from '../actions/action_types';

export default function limitModal(state = {}, action) {
  const data = action.payload || {};
  switch (action.type) {
    case OPENMODAL:
      return {
        ...state,
        blur: data.blur,
        modal: data.modal,
        symbol: data.symbol,
        full_symbol_name: data.full_symbol_name,
        price: data.price,
        slippage: data.slippage,
        expireTime: data.expireTime,
        max_amount_per_order: data.max_amount_per_order,
        disableUI: data.disableUI,
        term_currency: data.term_currency,
        instrument_id: data.instrument_id,
        ndf: data.ndf,
        settle_date: data.settle_date,
        fixing_date: data.fixing_date,
        tenor: data.tenor,
        doubleWidget: data.doubleWidget,
      };
    case UPDATEMODAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
