import { LOAD_INSTRUMENT_PRICING_READY } from '../actions/action_types';
import utilDateTime from '../utils/date-time';

export default function instrumentPricingReducer(state = null, action) {
  switch (action.type) {
    case LOAD_INSTRUMENT_PRICING_READY:
      return utilDateTime.getEtcDateFromUserTimeZone(new Date());
    default:
      return state;
  }
}
