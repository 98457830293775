import { SYSTEM_STATUS, MATCHER_STATUS } from '../actions/action_types';

const INITIAL_STATUS = {
  wsStatus: true,
  webSocketStatus: true,
  matchers: [],
};

export default function systemStatusReducer(state = INITIAL_STATUS, action) {
  switch (action.type) {
    case SYSTEM_STATUS:
      return { ...state, ...action.payload };
    case MATCHER_STATUS: {
      const matcher = action.payload;
      const { matchers } = state;
      const index = matchers.findIndex((m) => m.id === matcher.id);
      if (index >= 0) {
        matchers[index] = matcher;
      } else {
        matchers.push(matcher);
      }

      return { ...state, ...matchers };
    }
    default:
      return state;
  }
}
