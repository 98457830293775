/* global ENV */

function appEnvCfg() {
  const vars = {
    test: {
      IS_ENABLE_SENTRY_LOG: false,
      // CPU_PERF_TASK_DURATION_UPPER_THRESHOLD: 5,
      // CPU_PERF_TASK_DURATION_DATAPOINT_SIZE_LIMIT: 60,
      WEBSOCKET_LATENCY_DATAPOINT_SIZE_LIMIT: 24,
      WEBSOCKET_LATENCY_THRESHOLD_MINOR: 250,
      WEBSOCKET_LATENCY_THRESHOLD_MAJOR: 450,
    },
    development: {
      IS_ENABLE_SENTRY_LOG: false,
      // CPU_PERF_TASK_DURATION_UPPER_THRESHOLD: 5,
      // CPU_PERF_TASK_DURATION_DATAPOINT_SIZE_LIMIT: 60,
      WEBSOCKET_LATENCY_DATAPOINT_SIZE_LIMIT: 24,
      WEBSOCKET_LATENCY_THRESHOLD_MINOR: 250,
      WEBSOCKET_LATENCY_THRESHOLD_MAJOR: 450,
    },
    beta: {
      IS_ENABLE_SENTRY_LOG: true,
      // CPU_PERF_TASK_DURATION_UPPER_THRESHOLD: 5,
      // CPU_PERF_TASK_DURATION_DATAPOINT_SIZE_LIMIT: 60,
      WEBSOCKET_LATENCY_DATAPOINT_SIZE_LIMIT: 24,
      WEBSOCKET_LATENCY_THRESHOLD_MINOR: 250,
      WEBSOCKET_LATENCY_THRESHOLD_MAJOR: 450,
    },
    uat: {
      IS_ENABLE_SENTRY_LOG: true,
      // CPU_PERF_TASK_DURATION_UPPER_THRESHOLD: 5,
      // CPU_PERF_TASK_DURATION_DATAPOINT_SIZE_LIMIT: 60,
      WEBSOCKET_LATENCY_DATAPOINT_SIZE_LIMIT: 24,
      WEBSOCKET_LATENCY_THRESHOLD_MINOR: 250,
      WEBSOCKET_LATENCY_THRESHOLD_MAJOR: 450,
    },
    production: {
      IS_ENABLE_SENTRY_LOG: true,
      // CPU_PERF_TASK_DURATION_UPPER_THRESHOLD: 5,
      // CPU_PERF_TASK_DURATION_DATAPOINT_SIZE_LIMIT: 60,
      WEBSOCKET_LATENCY_DATAPOINT_SIZE_LIMIT: 24,
      WEBSOCKET_LATENCY_THRESHOLD_MINOR: 250,
      WEBSOCKET_LATENCY_THRESHOLD_MAJOR: 450,
    },
  };

  return vars[
    typeof ENV === 'string' && ENV.length > 0 ? ENV.toLowerCase() : 'production'
  ];
}

export default appEnvCfg;
