export default function returnStatusUtil(order) {
  var status = null;
  const qtyOpen = Number(order.qty_open);
  const qtyFilled = Number(order.qty_filled);

  if (
    (qtyOpen === 0 && qtyFilled > 0 && order.status === 'CANCELED') ||
    (qtyFilled > 0 && Number(order.qty_original) > qtyFilled)
  )
    status = 'PARTIALLY_FILLED';
  else status = order.status;
  return status;
}
