import React from 'react';
import getUrlLogo from '../../../utils/getUrlLogo';

const presenter = () => {
  const urlLogo = getUrlLogo({ app: window.APP_NAME, type: 'out' });

  const assets = {
    logo: {
      url: urlLogo,
      alt: 'Mextrader',
    },
  };

  const isDisplayLogo = !!(urlLogo && window.APP_NAME !== 'nologo');

  return Object.freeze({
    isDisplayLogo,
    assets,
  });
};

const Logo = () => {
  const presenting = presenter();

  return (
    <div data-testid="components:auth:logo:logo" className="login-form__head">
      {presenting.isDisplayLogo && (
        <img
          src={presenting.assets.logo.url}
          alt={presenting.assets.logo.alt}
        />
      )}
    </div>
  );
};
export default Logo;
