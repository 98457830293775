import PNotify from './pNotify';
import { audioViewerOrder } from '../../utils/sounds';

export const ViewerNotification = (data, opts) => {
  const { showNotification, soundViewer } = opts || {};
  if (!showNotification) {
    return null;
  }

  if (soundViewer) {
    audioViewerOrder.play().catch((err) => {
      console.warn(err);
    });
  }
  PNotify(data);
  return null;
};

export const viewerNotificationFields = ({ title, text, hide }) => {
  const notification = {
    title,
    text,
    hide,
    textTrusted: true,
    delay: 5000,
    mouseReset: false,
    styling: 'custom',
    maxTextHeight: null,
    addModelessClass: 'notifyFixed',
    icon: 'fas fa-check-circle fa-2x',
    sticker: false,
    closer: true,
    closerHover: false,
    stack: window.maxOpenClose,
    icons: {
      closer: 'fa fa-times',
    },
  };

  return notification;
};
