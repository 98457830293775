import { FOLLOW_ORDER } from '../actions/action_types';

export default function followOrdersReducer(state = [], action) {
  switch (action.type) {
    case FOLLOW_ORDER:
      if (
        action.payload.status === 'INACTIVE' &&
        state.includes(action.payload.order_id)
      ) {
        const newState = [...state];
        const index = newState.indexOf(action.payload.order_id);
        newState.splice(index, 1);
        return newState;
      }
      if (
        action.payload.status === 'ACTIVE' &&
        !state.includes(action.payload.order_id)
      ) {
        return [...state, action.payload.order_id];
      }
      return state;
    default:
      return state;
  }
}
