import update from 'react-addons-update';
import {
  POST_ASSET,
  UPDATE_ASSET,
  UPDATE_ASSET_RFQ,
  UPDATE_ASSET_PAIR_WIDGET,
} from '../actions/action_types';

export default function assetsReducer(state = [], action) {
  switch (action.type) {
    case POST_ASSET: // Adds one or multiple isntruments
      if (state.length) {
        const merge = mergeAssets(state, action.payload);
        return merge;
      }
      return [...action.payload];

    case UPDATE_ASSET:
      return updateInstrumentArray(state.slice(), action.payload);

    case UPDATE_ASSET_RFQ:
      return updateRfqInstrument([...state], action.payload);

    case UPDATE_ASSET_PAIR_WIDGET:
      return updateInstrumentPairWidgetLvls(state.slice(), action.payload);

    default:
      return state;
  }
}

const updateRfqInstrument = (instruments, asset) =>
  instruments.map((instrument) => {
    if (instrument.instrument_id === asset.instrument_id) {
      return {
        ...instrument,
        ...asset,
      };
    }
    return instrument;
  });

let updateInstrumentArray = (instruments, asset) => {
  const index = instruments.findIndex(
    (i) => Number(i.instrument_id) === Number(asset.instrument_id)
  );
  if (index < 0) {
    // Asset could not be found in the current subscription.
    console.warn('Instrument could not be found in the current array');
    return update(instruments, { $unshift: asset }); // todo: change it to add the array.
  }
  let newData;
  if (
    asset.settle_date &&
    Number(instruments[index].settle_date) !== Number(asset.settle_date)
  ) {
    newData = update(instruments[index], {
      settle_date: { $set: asset.settle_date },
    });
    instruments[index] = newData;
  }

  if (
    asset.fixing_date &&
    Number(instruments[index].fixing_date) !== Number(asset.fixing_date)
  ) {
    newData = update(instruments[index], {
      fixing_date: { $set: asset.fixing_date },
    });
    instruments[index] = newData;
  }

  return instruments;
};

const mergeAssets = (current, news) => {
  for (let i; i < news.length; i += 1) {
    const index = current.findIndex(
      (inst) => Number(inst.instrument_id) === Number(news[i].instrument_id)
    );
    if (index < 0) {
      current.unshift(news[i]);
    } else {
      current[index] = { ...current[index], ...news[i] };
    }
  }
  return [...current];
};

let updateInstrumentPairWidgetLvls = (instruments, asset) => {
  const index = instruments.findIndex(
    (i) => Number(i.instrument_id) === Number(asset.instrument_id)
  );

  if (index >= 0) {
    const newData = update(instruments[index], {
      pair_widget_vwap_levels: { $set: asset.pair_widget_vwap_levels.sort() },
    });
    instruments[index] = newData;
  } else console.warn('Instrument could not be found in the current array');

  return instruments;
};
