import { SET_PERMISSIONS } from '../actions/action_types';
import * as permissions from '../configs/permissions';

export default function permissionsReducer(state = permissions, action) {
  switch (action.type) {
    case SET_PERMISSIONS:
      return action.payload;
    default:
      return state;
  }
}
