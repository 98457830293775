import { CreditObject } from '~types/common';

type CreditInit = {
  doubleLine?: boolean;
  credit_type: string;
  credit_limit?: number;
  trading_power?: {
    buy: number;
    sell: number;
  };
  instruments: { [key: string]: number };
  current_usd_used_amount?: number;
};

const creditInit: CreditInit = {
  doubleLine: false,
  credit_type: 'unlimited',
  credit_limit: 0,
  trading_power: {
    buy: 0,
    sell: 0,
  },
  instruments: {},
  current_usd_used_amount: 0,
};

const getCreditCheckCurrent = (
  {
    doubleLine,
    instruments,
    credit_limit: creditLimit,
    credit_type: creditType,
    ...rest
  }: CreditObject,
  type: string
): CreditInit => {
  if (!doubleLine) {
    return {
      ...creditLimit,
      instruments,
      credit_type: creditType,
    };
  }

  if (type) {
    const typeCurrent = ['fx', 'ndf'].includes(type) ? type : 'fx';
    const key = `credit_limit_${typeCurrent}` as keyof typeof rest;
    const creditLimitCurrent = rest[key];

    return { ...creditLimitCurrent, instruments, credit_type: creditType };
  }

  return creditInit;
};

export { creditInit, getCreditCheckCurrent };
