import { createSelector } from 'reselect';
import { FollowClients } from '~types/common';

const followClientsSelector = createSelector(
  [
    (followClients: FollowClients) => followClients?.organizations,
    (followClients: FollowClients) => followClients?.users,
    (followClients: FollowClients) => followClients?.super_orders_viewer,
    (followClients: FollowClients) => followClients?.super_shadow_viewer,
    (followClients: FollowClients) => followClients?.orders,
    (followClients: FollowClients) => followClients?.shadow_orders,
    (followClients: FollowClients) => followClients?.show_own_cover,
    (followClients: FollowClients) => followClients?.show_viewer_cover,
    (followClients: FollowClients) => followClients?.viewerOrganizationIds,
    (followClients: FollowClients) => followClients?.viewerUserIds,
  ],
  (
    organizations = [],
    users = [],
    super_orders_viewer = false,
    super_shadow_viewer = false,
    orders = false,
    shadow_orders = false,
    show_own_cover = false,
    show_viewer_cover = false,
    viewerOrganizationIds = [],
    viewerUserIds = []
  ) => ({
    organizations,
    users,
    super_orders_viewer,
    super_shadow_viewer,
    orders,
    shadow_orders,
    show_own_cover,
    show_viewer_cover,
    viewerOrganizationIds,
    viewerUserIds,
  })
);

export default followClientsSelector;
