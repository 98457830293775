/* global ENV */
const devOriginRest = 'uat.latamfx.pro';
const devOriginWS = 'uat.latamfx.pro';

/**
 * Returns the REST entry api point
 * @returns {string}
 */
const apiOrigin = () => {
  if (ENV === 'development') {
    return `https://${devOriginRest}`;
  }

  return window.location.origin;
};

/**
 * Websocket Origin
 * @returns {string}
 */
export const wsOrigin = () => {
  if (ENV === 'development') {
    return `wss://${devOriginWS}/ws`;
  }
  return `wss://${window.location.host}/ws`;
};

export const httpOrigin = (location = '') => `${apiOrigin()}${location}`;

export default apiOrigin;
