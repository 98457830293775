import { WEBSOCKET_MONITOR } from './action_types';

// eslint-disable-next-line import/prefer-default-export
export function reduxWebsocketMonitor(payload) {
  return function reduxWebsocketMonitorAction(dispatch) {
    dispatch({
      type: WEBSOCKET_MONITOR,
      payload,
    });
  };
}
