import {
  CREDIT_CHECK_UPDATE,
  INSTRUMENTS_CREDIT_CHECK,
} from '../actions/action_types';

const CREDIT_INIT = {
  credit_limit: 0,
  counterparty: '',
  trading_power: {
    buy: 0,
    sell: 0,
  },
  current_usd_used_amount: 0,
};

const INITIAL_STATE = {
  organization_id: 0,
  credit_type: 'unlimited',
  credit_limit: CREDIT_INIT,
  credit_limit_fx: CREDIT_INIT,
  credit_limit_ndf: CREDIT_INIT,
  instruments: {},
  doubleLine: false,
};

const clearCreditLimit = (creditLimitTmp) => {
  const {
    trading_power: { buy, sell },
    credit_limit: creditLimitData,
    current_usd_used_amount: currentUsdUsedAmountData,
    counterparty,
  } = creditLimitTmp;

  return {
    trading_power: {
      buy: Number(buy),
      sell: Number(sell),
    },
    credit_limit: Number(creditLimitData),
    counterparty,
    current_usd_used_amount: Number(currentUsdUsedAmountData),
  };
};

/**
 * Parse values of the credit check and returns them to the credit status reducer
 * @param {*} state
 * @param {*} action
 */
const creditCheckReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREDIT_CHECK_UPDATE:
      if (action.payload == null)
        return {
          ...state,
          organization_id: INITIAL_STATE.organization_id,
          credit_type: INITIAL_STATE.credit_type,
          credit_limit: INITIAL_STATE.credit_limit,
          credit_limit_fx: INITIAL_STATE.credit_limit_fx,
          credit_limit_ndf: INITIAL_STATE.credit_limit_ndf,
          doubleLine: INITIAL_STATE.doubleLine,
        };
      try {
        const data = action.payload || {};

        const newState = {
          ...state,
          organization_id: data.organization_id,
          credit_type: data.credit_type,
        };

        if (!data.credit_limit_fx && !data.credit_limit_ndf) {
          return {
            ...newState,
            credit_limit: clearCreditLimit(data.credit_limit),
            doubleLine: false,
          };
        }
        if (data.credit_limit_fx && data.credit_limit_ndf) {
          return {
            ...newState,
            credit_limit_fx: clearCreditLimit(data.credit_limit_fx),
            credit_limit_ndf: clearCreditLimit(data.credit_limit_ndf),
            doubleLine: true,
          };
        }
      } catch (err) {
        return state;
      }

      return state;
    case INSTRUMENTS_CREDIT_CHECK:
      return {
        ...state,
        instruments: action.payload || {},
      };
    default:
      return state;
  }
};

export default creditCheckReducer;
