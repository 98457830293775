import { alert as PNotify, defaultModules, Stack } from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import * as PNotifyMobile from '@pnotify/mobile';
import '@pnotify/mobile/dist/PNotifyMobile.css';
import PNotifyFontAwesome5Fix from '@pnotify/font-awesome5-fix';
import PNotifyFontAwesome5 from '@pnotify/font-awesome5';

defaultModules.set(PNotifyFontAwesome5Fix, {});
defaultModules.set(PNotifyFontAwesome5, {});
defaultModules.set(PNotifyMobile, {});

if (typeof window.maxOpenClose === 'undefined') {
  window.maxOpenClose = new Stack({
    dir1: 'down',
    dir2: 'left',
    firstpos1: 25,
    firstpos2: 25,
    modal: false,
    maxOpen: 50,
    maxStrategy: 'close',
    maxClosureCausesWait: false,
  });
}

export default PNotify;
