import repository from './repository';
import lang from '../lang/langAuth';
import { browserPreferredLang } from '../../../utils/language';

type AuthLanguage = 'es' | 'en' | 'pt';

type AuthTranslationItem = {
  en: string;
  es: string;
  pt: string;
};

const transAuth: { [key: string]: AuthTranslationItem } = lang;

interface FormState {
  isTouched: boolean;
  isError: boolean;
  isDisabled: boolean;
  isRequired: boolean;
  className: string;
  value: string;
  validators: Validator[];
}

interface Validator {
  rule: string;
  feedback: string;
}

interface Translations {
  label: {
    username: string;
    password: string;
    authnError: string;
  };
  btn: {
    signIn: string;
  };
}

interface PresenterArgs {
  ID_CTRL_USERNAME: string;
  ID_CTRL_PASSWORD: string;
}

interface PresenterReturn {
  manageCtrlState: typeof manageCtrlState;
  initialFormState: { [key: string]: FormState };
  handleSubmit: ({
    username,
    password,
  }: {
    username: string;
    password: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<any>;
  trans: Translations;
}

export type CtrlState = {
  [key: string]: {
    value: string;
    className: string;
    isRequired: boolean;
  };
};

interface ManageCtrlStateArgs {
  state: CtrlState;
  name: string;
  value: string;
}

const manageCtrlState = ({ state, name, value }: ManageCtrlStateArgs) => {
  const def = { ...state[name] };

  return {
    ...state,
    [name]: {
      ...def,
      isTouched: true,
      value,
    },
  };
};

function presenter({
  ID_CTRL_USERNAME,
  ID_CTRL_PASSWORD,
}: PresenterArgs): PresenterReturn {
  const repo = repository();
  const initialFormState = {
    [ID_CTRL_USERNAME]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      isRequired: true,
      className: 'input--login',
      value: window.ENV === 'development' ? 'trader1' : '',
      validators: [
        {
          rule: 'required',
          feedback: 'Email: required',
        },
      ],
    },
    [ID_CTRL_PASSWORD]: {
      isTouched: false,
      isError: false,
      isDisabled: false,
      isRequired: true,
      className: 'input--login',
      value: window.ENV === 'development' ? '123' : '',
      validators: [
        {
          rule: 'required',
          feedback: 'Password: required',
        },
      ],
    },
  };

  const langDefault = window.APP_NAME !== 'latamfx' ? 'en' : 'es';
  const langFromBrowser = <AuthLanguage>browserPreferredLang();

  const usernameTrans =
    transAuth.username[langFromBrowser] || transAuth.username[langDefault];
  const passwordTrans =
    transAuth.password[langFromBrowser] || transAuth.password[langDefault];
  const authnErrorTrans =
    transAuth.error_authentication[langFromBrowser] ||
    transAuth.error_authentication[langDefault];
  const signInTrans =
    transAuth.button_login[langFromBrowser] ||
    transAuth.button_login[langDefault];

  const trans = {
    label: {
      username: usernameTrans,
      password: passwordTrans,
      authnError: authnErrorTrans,
    },
    btn: {
      signIn: signInTrans,
    },
  };

  const handleSubmit = ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => repo.apiAuthnRequest({ username, password });

  return Object.freeze({
    manageCtrlState,
    initialFormState,
    handleSubmit,
    trans,
  });
}

export default presenter;
