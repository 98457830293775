import { SET_HISTORICAL_ORDERS } from '../actions/action_types';

export default function historicalOrdersReducer(state = [], action) {
  switch (action.type) {
    case SET_HISTORICAL_ORDERS:
      return action.payload;
    default:
      return state;
  }
}
