import { createSelector } from 'reselect';
import RootState from '~types/rootState';

export const usernameSelector = createSelector(
  [({ userDetails }: RootState) => userDetails],
  ({ username }) => username
);

export const userDetailsSelector = createSelector(
  [({ userDetails }: RootState) => userDetails],
  ({
    userId,
    organizationId,
    username,
    organization,
    rateEngineEnabled: rateEngineAdmin,
    shadowAdmin,
    isViewer,
    isEnableWebsocketRouting,
  }) => ({
    userId,
    organizationId,
    username,
    organization,
    rateEngineAdmin,
    shadowAdmin,
    isViewer,
    isEnableWebsocketRouting,
  })
);

export const tradingEnabledSelector = ({ user_permissions }: RootState) =>
  user_permissions.order_add === '1';
