import {
  setHours,
  setMinutes,
  setSeconds,
  isAfter,
  isBefore,
  isEqual,
  format,
  addDays,
  subDays,
} from 'date-fns';

import { zonedTimeToUtc, utcToZonedTime, toDate } from 'date-fns-tz';

import { resetTimeDefault, ymdDash, ymd } from '../configs/dateFormatStr';

const getTimeZoneValue = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const getIsOutOfHours = (currentDate: Date, timeZone?: string): boolean => {
  const userEtcDate = getEtcDateFromUserTimeZone(currentDate, timeZone);

  const weekDay = userEtcDate.getDay();
  if (weekDay === 6) {
    return true;
  }

  if (weekDay >= 1 && weekDay <= 4) {
    return false;
  }

  if (weekDay === 5) {
    const outOfHoursTimeStart = setHours(
      setMinutes(setSeconds(userEtcDate, 59), 59),
      16
    );
    if (isAfter(userEtcDate, outOfHoursTimeStart)) {
      return true;
    }
  }

  if (weekDay === 0) {
    const outOfHoursTimeEnd = setHours(
      setMinutes(setSeconds(userEtcDate, 0), 0),
      17
    );
    if (isBefore(userEtcDate, outOfHoursTimeEnd)) {
      return true;
    }
  }

  return false;
};

const getEtcDateFromUserTimeZone = (
  currentDate: Date,
  timeZone?: string
): Date => {
  const userTimeZone = timeZone == null ? getTimeZoneValue() : timeZone;
  const userUtcDate = zonedTimeToUtc(currentDate, userTimeZone);
  const userEtcDate = utcToZonedTime(userUtcDate, 'America/New_York');

  return userEtcDate;
};

const isAfterTime = (curDate: Date, resetTime?: string): boolean => {
  const etcDate = getEtcDateFromUserTimeZone(curDate);
  const etc5pm = getEtcDateFromUserTimeZone(
    toDate(
      `${format(getEtcDateFromUserTimeZone(curDate), ymdDash)} ${
        resetTime || resetTimeDefault
      }`,
      {
        timeZone: 'America/New_York',
      }
    )
  );

  return isSameOrAfter(etcDate, etc5pm);
};

const isSameOrAfter = (dt: Date, startDate: Date): boolean =>
  isAfter(dt, startDate) || isEqual(dt, startDate);

const isSameOrBefore = (dt: Date, endDate: Date): boolean =>
  isBefore(dt, endDate) || isEqual(dt, endDate);

function todayYesterdaysTradeDate() {
  const currentDate = getEtcDateFromUserTimeZone(new Date());
  const currentDateForYesterdayDate = getEtcDateFromUserTimeZone(new Date());

  const after5pm = isAfterTime(new Date(), resetTimeDefault);
  const todayTradeDate = after5pm ? addDays(currentDate, 1) : currentDate;

  const yesterdayIfAfter5pmNotMonday = after5pm
    ? currentDateForYesterdayDate
    : subDays(currentDateForYesterdayDate, 1);

  const yesterdayIfAfter5pmIfMonday = after5pm
    ? currentDateForYesterdayDate
    : subDays(currentDateForYesterdayDate, 3);

  const yesterdaysTradeDate =
    currentDateForYesterdayDate.getDay() === 1
      ? yesterdayIfAfter5pmIfMonday
      : yesterdayIfAfter5pmNotMonday;

  return {
    todayTradeDate: format(todayTradeDate, ymd),
    yesterdaysTradeDate: format(yesterdaysTradeDate, ymd),
  };
}

export default {
  getTimeZoneValue,
  getIsOutOfHours,
  getEtcDateFromUserTimeZone,
  isAfterTime,
  isSameOrAfter,
  isSameOrBefore,
  todayYesterdaysTradeDate,
};
