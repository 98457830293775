import React from 'react';
import PropTypes from 'prop-types';

const Loading = () => (
  <div id="dashboard-loading">
    <span className="spinner" />
  </div>
);

export const LoadingButton = ({ cClass }) => (
  <button type="button" className={cClass}>
    <i className="fas fa-spinner" />
  </button>
);

LoadingButton.defaultProps = {
  cClass: '',
};

LoadingButton.propTypes = {
  cClass: PropTypes.string,
};

export default Loading;
