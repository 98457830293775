import { SET_LVLS } from '../actions/action_types';
import { creditInit } from '../utils/creditCheck';

export const DEFAULT_LVL = {
  creditCheck: creditInit,
  play: false,
  buy_error: false,
  sell_error: false,
  activeOrders: { buy: false, sell: false },
  qtys: { buy: '', sell: '', constructorIs: true },
  spreads: { buy: '', sell: '', constructorIs: true },
  identifiers: {
    buy: null,
    sell: null,
  },
  currentOrders: {
    BUY: null,
    SELL: null,
  },
  order_completed: false,
  takeTopBuyOrder: null,
  takeTopSellOrder: null,
};

export default (state = {}, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case SET_LVLS: {
      const { identifier: peggIdentifier, lvl, state: updateState } = payload;
      const newState = {
        ...state,
        [peggIdentifier]: {
          ...(state[peggIdentifier] || {}),
          [lvl]: {
            ...DEFAULT_LVL,
            ...(state[peggIdentifier] && state[peggIdentifier][lvl]
              ? state[peggIdentifier][lvl]
              : {}),
            ...updateState,
          },
        },
      };
      return newState;
    }
    default:
      return state;
  }
};
