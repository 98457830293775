import isEmpty from 'lodash/isEmpty';
import {
  INIT_PEGG_STATE,
  ALTER_PEGG_STATE,
  TOGGLE_LVL_TO_PEGG_STATE,
  HANDLE_LVL_INPUT,
  SET_LVLS,
} from './action_types';
import { addSubscription, stopSubscription } from './subscriptions';
import { reduxSendInstrumentSettings } from './ui';

/**
 * Convert local config to User Settings shape
 * @param {Object} config
 */
export const configToSettings = (config) => {
  const newSettings = {};
  if (config.priceLimits) {
    newSettings.price_limits = config.priceLimits;
  }
  if (config.fwdPoints) {
    newSettings.fwd_points = {
      buy: config.fwdPoints.bid,
      sell: config.fwdPoints.offer,
    };
  }
  if (config.expirationTime) {
    newSettings.expiration_time = config.expirationTime;
  }
  if (config.tbo) newSettings.tbo = config.tbo;
  if (config.durationTime) newSettings.duration_time = config.durationTime;
  if (config.minAmount) newSettings.min_amount = config.minAmount;
  // TODO: Check if this actually exists
  if (config.maxAmount) newSettings.max_amount = config.maxAmount;
  if (config.instrument) newSettings.instrument = config.instrument;
  if (config.tenor) newSettings.tenor = config.tenor;
  if (config.sourceId) newSettings.sourceId = config.sourceId;
  if (config.tradingId) newSettings.tradingId = config.tradingId;

  if (config.autoHedge) newSettings.stateAutoHedge = config.autoHedge;
  return newSettings;
};

export const initPeggWidget =
  (identifier, initial = false) =>
  (dispatch) => {
    // Add subscriptions if initial comming from settings
    if (initial) {
      if (initial.tradingId)
        addSubscription(
          initial.tradingId,
          'DEPTH',
          `marketMaker-${identifier}`
        )(dispatch);
      if (initial.sourceId)
        addSubscription(
          initial.sourceId,
          'DEPTH',
          `marketMaker-${identifier}`
        )(dispatch);
    }
    dispatch({
      type: INIT_PEGG_STATE,
      payload: { identifier, initial },
    });
  };

export const alterPeggWidget = (identifier, fieldValueObject) => (dispatch) => {
  if (fieldValueObject.tradingId) {
    if (fieldValueObject.prevTradingId)
      stopSubscription(
        fieldValueObject.prevTradingId,
        'DEPTH',
        `marketMaker-${identifier}`
      )(dispatch);
    addSubscription(
      fieldValueObject.tradingId,
      'DEPTH',
      `marketMaker-${identifier}`
    )(dispatch);
  }
  if (fieldValueObject.sourceId) {
    if (fieldValueObject.prevSourceId)
      stopSubscription(
        fieldValueObject.prevSourceId,
        'DEPTH',
        `marketMaker-${identifier}`
      )(dispatch);
    addSubscription(
      fieldValueObject.sourceId,
      'DEPTH',
      `marketMaker-${identifier}`
    )(dispatch);
  }

  if (
    Object.keys(fieldValueObject).includes('instrument') ||
    Object.keys(fieldValueObject).includes('tenor') ||
    Object.keys(fieldValueObject).includes('sourceId') ||
    Object.keys(fieldValueObject).includes('tradingId') ||
    Object.keys(fieldValueObject).includes('tbo') ||
    Object.keys(fieldValueObject).includes('durationTime') ||
    Object.keys(fieldValueObject).includes('priceLimits') ||
    Object.keys(fieldValueObject).includes('minAmount') ||
    Object.keys(fieldValueObject).includes('expirationTime') ||
    Object.keys(fieldValueObject).includes('autoHedge') ||
    Object.keys(fieldValueObject).includes('fwdPoints')
  ) {
    const newSettings = configToSettings(fieldValueObject);

    if (!isEmpty(newSettings))
      reduxSendInstrumentSettings(
        newSettings,
        'market_maker',
        identifier
      )(dispatch);
    // We should send a notification to each individual level to cancel orders
    // If any of these changes we have to cancel all orders comming from this widget
    fieldValueObject.cancelPeggOrders = true;
  }

  dispatch({
    type: ALTER_PEGG_STATE,
    payload: {
      identifier,
      fieldValueObject,
    },
  });
};

/**
 *
 * @param {String} identifier - current pegg widget identifier
 * @param {*} lvl - Unique identifier to be assigned to lvl
 * @param {*} action - add or remove
 */
export const toggleLvlToPeggWidget = (identifier, lvl, action) => (dispatch) =>
  dispatch({
    type: TOGGLE_LVL_TO_PEGG_STATE,
    payload: {
      identifier,
      lvl,
      action,
    },
  });

export const handleLvlInput = (identifier, lvl, objectKeyValue) => (dispatch) =>
  dispatch({
    type: HANDLE_LVL_INPUT,
    payload: {
      identifier,
      lvl,
      objectKeyValue,
    },
  });

export const handleSetState = (identifier, lvl, state) => (dispatch) =>
  dispatch({
    type: SET_LVLS,
    payload: {
      identifier,
      lvl,
      state,
    },
  });
