const langAuth = {
  username: {
    en: 'Username',
    es: 'Usuario',
    pt: 'Usuario',
  },
  password: {
    en: 'Password',
    es: 'Contraseña',
    pt: 'Senha',
  },
  button_login: {
    en: 'Login',
    es: 'Iniciar sesión',
    pt: 'Conecte-se',
  },
  error_authentication: {
    en: 'Username or Password incorrect',
    es: 'Usuario o contraseña incorrecto',
    pt: 'Usuário ou senha incorretos',
  },
};

export default langAuth;
