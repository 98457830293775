import React from 'react';

const NotFound404 = () => (
  <div style={styles.main}>
    <h2 style={styles.title}>404 Page not found</h2>
  </div>
);

const styles = {
  main: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '4em',
  },
};

export default NotFound404;
