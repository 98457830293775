import { SET_RESTING_STATE } from '../actions/action_types';

export const defaultRestingOrdersInitialState = {
  levels: [{ price: '', stopPrice: '', amount: '', side: 'BUY' }],
  startDate: null,
  endDate: null,
  orderType: 'LIMIT',
  tif: 'GTC',
  instruments: [],
  tenorList: [],
  instrumentIdSelected: 0,
  symbolSelected: null,
  tenorSelected: null,
  maxAmountPerOrder: 0,
};

export default function restingOrderReducer(state = {}, action) {
  if (action.type === SET_RESTING_STATE) {
    return {
      ...state,
      ...Object.keys(action.payload).reduce((prev, key) => {
        const actual = state[key] || defaultRestingOrdersInitialState;
        prev[key] = {
          ...actual,
          ...action.payload[key],
        };
        return prev;
      }, {}),
    };
  }
  return state;
}
