import {
  INIT_PEGG_STATE,
  ALTER_PEGG_STATE,
  TOGGLE_LVL_TO_PEGG_STATE,
  HANDLE_LVL_INPUT,
} from '../actions/action_types';

const levelInitialState = {
  amount: {
    buy: 0,
    sell: 0,
  },
  spread: {
    buy: '',
    sell: '',
  },
  maxAmount: {
    buy: 0,
    sell: 0,
  },
  minDifference: {
    buy: 0,
    sell: 0,
  },
  active: {
    buy: false,
    sell: false,
  },
  play: false,
  ordersMM: {
    buy: {
      searchIdentifier: null,
      current: null,
      past: [],
    },
    sell: {
      searchIdentifier: null,
      current: null,
      past: [],
    },
  },
  ordersTop: {
    buy: {
      searchIdentifier: null,
      current: null,
    },
    sell: {
      searchIdentifier: null,
      current: null,
    },
  },
};

const peggInitialState = {
  // Form state
  instrument: null,
  tenor: {
    source: null,
    trading: null,
  },
  sourceId: null,
  tradingId: null,
  sameTenor: false,
  priceLimits: {
    min: '',
    max: '',
  },
  fwdPoints: {
    bid: 0,
    offer: 0,
  },
  expirationTime: 0,
  tbo: 5,
  durationTime: 5,
  minAmount: 0,
  maxAmount: 0,

  lvls: [
    {
      id: ((Math.random() * 0xffffff) << 0).toString(36),
      ...levelInitialState,
    },
  ],

  // Book
  book: {
    source: {},
    trading: {},
    timestamp: new Date().getTime(),
  },

  // Auto hedge
  autoHedge: {
    profitTakingSpread: false,
    valueProfitTakingSpread: '0',
    stopLossSpread: false,
    valueStopLossSpread: '0',
    onShoreSpot: false,
    offShoreNDF: false,
  },

  // UI State
  fullSettings: true,
  showExpirationTime: false,
};

export default function peggWidgetReducer(state = {}, action) {
  const { type, payload = {} } = action;
  switch (type) {
    case INIT_PEGG_STATE:
      return {
        ...state,
        [payload?.identifier]: !payload?.initial
          ? peggInitialState
          : {
              ...peggInitialState,
              ...action.payload.initial,
            },
      };
    case ALTER_PEGG_STATE: {
      const { fieldValueObject, identifier } = payload;

      let lvlReferences = state[identifier].lvls;
      // This param always comes when we cancel orders
      if (fieldValueObject.cancelPeggOrders) {
        // We have to notify all lvls that their orders are going to be canceled
        // So we mutate lvls object
        lvlReferences = state[identifier].lvls.map((lvl) => ({
          ...lvl,
          play: false,
        }));
      }

      return {
        ...state,
        [identifier]: {
          ...state[identifier],
          lvls: lvlReferences,
          ...fieldValueObject,
          cancelPeggOrders: false,
        },
      };
    }
    case TOGGLE_LVL_TO_PEGG_STATE: {
      const {
        identifier: toggleIdentifier,
        lvl,
        action: alterAction,
      } = action.payload;

      let newLvls = [...state[toggleIdentifier].lvls];
      if (alterAction === 'add') {
        newLvls = [...newLvls, { id: lvl, ...levelInitialState }];
      } else {
        newLvls = newLvls.filter((item) => item.id !== lvl);
      }

      return {
        ...state,
        [toggleIdentifier]: {
          ...state[toggleIdentifier],
          lvls: newLvls,
        },
      };
    }
    case HANDLE_LVL_INPUT: {
      const {
        identifier: handleIdentifier,
        lvl: id,
        objectKeyValue,
      } = action.payload;
      const lvlIdentifier = state[handleIdentifier].lvls.findIndex(
        (lvl) => lvl.id === id
      );
      if (lvlIdentifier === -1) return state;
      const copyArray = [...state[handleIdentifier].lvls];
      copyArray[lvlIdentifier] = {
        ...copyArray[lvlIdentifier],
        ...objectKeyValue,
      };
      return {
        ...state,
        [handleIdentifier]: {
          ...state[handleIdentifier],
          lvls: copyArray,
        },
      };
    }
    default:
      return state;
  }
}
