const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

/**
 * Gets the correct language text
 * @param {Object} textOptions
 * @param {string} lang - ["en", "es", "po"]
 * @param  {...any} lvls - strings
 */
const language = (textOptions, lang, ...lvls) => {
  const value = get(lvls, textOptions);
  return value ? value[lang] : lvls[lvls.length - 1].toString();
};

export default language;

export const browserPreferredLang = () =>
  window?.navigator?.language?.split('-')?.[0];
