import debug from 'debug';

/**
 *
 * @param {string} prefix
 * @returns logger
 */
const frontLogger = (prefix) => {
  const info = debug(`UI:inf:${prefix}`);
  const dev = debug(`UI:dev:${prefix}`);
  const error = debug(`UI:err:${prefix}`);
  const warn = debug(`UI:warn:${prefix}`);
  const trace = debug(`UI:trc:${prefix}`);

  return {
    info: (message, ...args) => info(message, ...args),
    dev: (message, ...args) => dev(message, ...args),
    error: (message, ...args) => error(message, ...args),
    warn: (message, ...args) => warn(message, ...args),
    trace: (message, ...args) => trace(message, ...args),
  };
};

export default frontLogger;
