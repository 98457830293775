import { DISABLE_UI } from '../actions/action_types';

export default function disableUIReducer(state = {}, action) {
  switch (action.type) {
    case DISABLE_UI:
      return { ...state, disable_ui: action.payload.disable_ui };
    default:
      return state;
  }
}
