import { SET_ACCOUNTS } from '../actions/action_types';

export default function accountsReducer(
  state = {
    list: [],
    lastFetchedAt: null,
  },
  action
) {
  switch (action.type) {
    case SET_ACCOUNTS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
