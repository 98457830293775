import { WhiteLabels } from '~types/common';
import wls from '../configs/wl';

const wl: WhiteLabels = wls;

interface AppType {
  app: string;
  type: 'in' | 'out';
}

const getUrlLogo = ({ app, type }: AppType): string => {
  let urlLogo = '';
  const arrayAppsPng: string[] = [
    'msri',
    'axiafx',
    'sberbankfx',
    'edfmanfx',
    'multivafx',
    'bcpfx',
    'cibanco',
    'bancolombia',
    'makor',
    'edgefxprime',
    'shinhan',
    'banorte',
    'ipscorp',
    'hanabank',
    'kb'
  ];

  if (type === 'in') {
    urlLogo = `static/images/${wl[app]?.branding || app}-logo.${
      arrayAppsPng.includes(app) ? 'png' : 'svg'
    }`;
  } else {
    urlLogo = `/static/images/${wl[app]?.branding || app}-logo-by.${
      arrayAppsPng.includes(app) ? 'png' : 'svg'
    }`;
  }

  urlLogo += `?t=${new Date().getTime()}`;
  return urlLogo;
};

export default getUrlLogo;
