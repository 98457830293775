import {
  captureException as sentryCaptureException,
  configureScope as sentryConfigureScope,
  captureMessage as sentryCaptureMessage,
  addBreadcrumb as sentryAddBreadcrumb,
} from '@sentry/react';

/**
 * Logging utility
 *
 * Pass redux store in with logger.setStore(store) at app bootstrap
 * (internally) read state with store.getState()
 */

const logger = (() => {
  let store = null;
  let pageVisibilityApiPropName = null;
  let visibilityChangeEvtType = null;
  let isHidden = false;

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    pageVisibilityApiPropName = 'hidden';
    visibilityChangeEvtType = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    pageVisibilityApiPropName = 'msHidden';
    visibilityChangeEvtType = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    pageVisibilityApiPropName = 'webkitHidden';
    visibilityChangeEvtType = 'webkitvisibilitychange';
  }

  function handlePageVisibilityChange() {
    if (document[pageVisibilityApiPropName]) {
      isHidden = true;
    } else {
      isHidden = false;
    }
  }

  // Feature detect
  if (
    typeof document.addEventListener === 'undefined' ||
    pageVisibilityApiPropName == null
  ) {
    logWarning(
      'Feature detection failed: addEventListener or page visibility API not supported'
    );
  } else {
    document.addEventListener(
      visibilityChangeEvtType,
      handlePageVisibilityChange,
      false
    );
  }

  const getPopoutState = (username) => {
    const domain = 'flex_layout';
    const storageKey = `_${username}_${domain}`;
    const layoutState = localStorage.getItem(storageKey);

    if (typeof layoutState === 'string') {
      try {
        const { openPopouts } = JSON.parse(layoutState);

        return openPopouts == null || !Array.isArray(openPopouts)
          ? []
          : openPopouts;
      } catch (err) {
        return [];
      }
    }

    return [];
  };

  /**
   * @typedef {Object} tagObjects - tags
   * @property {string} key - tag key
   * @property {string} value - tag value
   */

  /**
   * @param {Object} userAuthn - authenticated user state
   * @param {string} userAuthn.username - username
   * @param {number} userAuthn.orgId - organization id
   * @param {string} userAuthn.orgName - organization name
   * @param {tagObjects[]} [tagObjects] - tags
   */
  const setScope = ({ username, orgId, orgName }, tagObjects) => {
    if (
      Array.isArray(tagObjects) &&
      tagObjects.length === 0 &&
      username == null
    )
      return;
    sentryConfigureScope(function configureScope(scope) {
      if (tagObjects && Array.isArray(tagObjects)) {
        tagObjects.forEach((tagObject) => {
          const { key, value } = tagObject;
          if (key == null || value == null) return;
          scope.setTag(key, value.toString());
        });
      }
      if (username == null) return;
      scope.setUser({
        id: `username:${username} | org-id:${orgId} | org-name:${orgName}`,
        username,
      });
      scope.setTag(
        'has_popouts',
        (!!getPopoutState(username).length).toString()
      );
      scope.setTag('is_openfin', Boolean(window.fin).toString());
    });
  };

  /**
   * @param {tagObjects[]} [tags] - tags
   */
  const setContext = (tags) => {
    // let tagsFromStore = [];
    let websocketOpenCtx = [];
    let websocketNotOpenCtx = [];
    let isWebsocketConnected = null;
    if (store != null && typeof store.getState === 'function') {
      const state = store.getState();
      const websocketState = state.websocketMonitor;
      const {
        readyState,
        readyStateTxt,
        bufferedAmount,
        url,
        code,
        codeTxt,
        reason,
        wasClean,
        latencyMin,
        latencyMax,
        latencyP95,
        latencyP99,
      } = websocketState;
      isWebsocketConnected = readyState === 1;
      websocketOpenCtx = [
        { key: 'ws_readystate', value: readyStateTxt },
        { key: 'ws_url', value: url },
        { key: 'ws_latency', value: parseInt(state.latency ?? 0, 10) },
        { key: 'ws_latency_min', value: parseInt(latencyMin ?? 0, 10) },
        { key: 'ws_latency_max', value: parseInt(latencyMax ?? 0, 10) },
        { key: 'ws_latency_p95', value: parseFloat(latencyP95.toFixed(3)) },
        { key: 'ws_latency_p99', value: parseFloat(latencyP99.toFixed(3)) },
      ];
      websocketNotOpenCtx = [
        { key: 'ws_buffered', value: bufferedAmount },
        { key: 'ws_code', value: code },
        { key: 'ws_code_txt', value: codeTxt },
        { key: 'ws_reason', value: reason || 'unknown' },
        { key: 'ws_wasClean', value: wasClean },
      ];
      // tagsFromStore = [
      // { key: 'cpu_cores', value: state.browserPerf.cpuCoresAvailable },
      // { key: 'cpu_task_duration_min', value: state.browserPerf.cpuTaskMin },
      // { key: 'cpu_task_duration_max', value: state.browserPerf.cpuTaskMax },
      // { key: 'cpu_task_duration_last', value: state.browserPerf.cpuTaskLast },
      // { key: 'cpu_task_duration_p95', value: state.browserPerf.cpuTaskP95 },
      // {
      //   key: 'ram_js_heap_size_limit',
      //   value: state.browserPerf.ramJsHeapSizeLimit,
      // },
      // {
      //   key: 'ram_js_heap_size_total',
      //   value: state.browserPerf.ramTotalJSHeapSize,
      // },
      // {
      //   key: 'ram_js_heap_size_used',
      //   value: state.browserPerf.ramUsedJSHeapSize,
      // },
      // ];
    }
    const tagObjects = tags && Array.isArray(tags) ? tags : [];
    const username = sessionStorage.getItem('global_username');
    const orgId = sessionStorage.getItem('global_org_id');
    const orgName = sessionStorage.getItem('global_organization');
    const tagObjectsWithDefaults = [
      ...tagObjects,
      { key: 'username', value: username },
      { key: 'organization.id', value: orgId },
      { key: 'organization.name', value: orgName },
      { key: 'is_hidden', value: isHidden },
      { key: 'app_name', value: window.APP_NAME },
      ...websocketOpenCtx,
      ...(!isWebsocketConnected ? websocketNotOpenCtx : []),
      // ...tagsFromStore,
    ];
    setScope({ username, orgId, orgName }, tagObjectsWithDefaults);
    addBreadcrumbPopouts(username);
  };

  const setStore = (storeObj) => {
    store = storeObj;
  };

  const addBreadcrumbPopouts = (username) => {
    const openPopoutsState = getPopoutState(username);
    const breadcrumbs = openPopoutsState.map((popout) => ({
      title: popout.content.title,
      id: popout.content.id,
    }));

    sentryAddBreadcrumb({
      type: 'debug',
      category: 'popouts',
      message: JSON.stringify(breadcrumbs),
      level: 'debug',
    });
  };

  const isError = (obj) =>
    // Source: https://stackoverflow.com/a/61958148/5082594
    Object.prototype.toString.call(obj) === '[object Error]';

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const log = (msg, { tags, isLogIfHidden = true } = {}) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    sentryCaptureMessage(msg, 'log');
  };

  /**
   * @param {Error} error - JS Error
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logCriticalError = (error, { tags, isLogIfHidden = true } = {}) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    if (isError(error)) {
      sentryCaptureException(error, 'critical');
    } else {
      sentryCaptureMessage(error, 'critical');
    }
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logCriticalMsg = (msg, { tags, isLogIfHidden = true } = {}) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    sentryCaptureMessage(msg, 'critical');
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logDebug = (msg, { tags, isLogIfHidden = true } = {}) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    sentryCaptureMessage(msg, 'debug');
  };

  /**
   * @param {Error} error - JS Error
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logError = (error, { tags, isLogIfHidden = true } = {}) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    if (isError(error)) {
      sentryCaptureException(error, 'error');
    } else {
      sentryCaptureMessage(error, 'error');
    }
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logFatal = (msg, { tags, isLogIfHidden = true } = {}) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    sentryCaptureMessage(msg, 'fatal');
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logInfo = (msg, { tags, isLogIfHidden = true } = {}) => {
    if (isHidden && !isLogIfHidden) return;
    setContext(tags);
    sentryCaptureMessage(msg, 'info');
  };

  /**
   * @param {string} msg - message
   * @param {Object} [ctx] - tags, breadcrumbs & options
   * @param {tagObjects[]} [ctx.tags] - tags
   * @param {*} [ctx.breadcrumbs] - breadcrumbs (not implemented yet)
   * @param {boolean} [ctx.isLogIfHidden] - log if page NOT visible (default = true)
   */
  const logWarning = (msg, { tags, isLogIfHidden = true } = {}) => {
    if (isHidden && !isLogIfHidden) return;

    setContext(tags);
    sentryCaptureMessage(msg, 'warning');
  };

  return {
    log,
    logCriticalError,
    logCriticalMsg,
    logDebug,
    logError,
    logFatal,
    logInfo,
    logWarning,
    setStore,
    setContext,
  };
})();
export default logger;
