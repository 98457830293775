import { BOOK_UPDATE } from '../actions/action_types';

export default function bookUpdateReducer(
  state = { bids: [], asks: [] },
  action
) {
  switch (action.type) {
    case BOOK_UPDATE:
      return action.payload;
    default:
      return state;
  }
}
