import { WEBSOCKET_MONITOR, LATENCY } from '../actions/action_types';
import { socketReadyState, socketStatusCodeMappings } from '../constants';
import { formatDateToTime } from '../utils/formatDate';
import { insertionSortByObjPropArray } from '../utils/sort';
import { calcQuartile } from '../utils/stats';
import appEnvCfg from '../configs/app';

const WEBSOCKET_LATENCY_TIMESERIES_SIZE_LIMIT =
  appEnvCfg().WEBSOCKET_LATENCY_DATAPOINT_SIZE_LIMIT;

function getStatusCodeString(code) {
  if (code >= 0 && code <= 999) {
    return '(Unused)';
  }
  if (code >= 1016) {
    if (code <= 1999) {
      return '(For WebSocket standard)';
    }
    if (code <= 2999) {
      return '(For WebSocket extensions)';
    }
    if (code <= 3999) {
      return '(For libraries and frameworks)';
    }
    if (code <= 4999) {
      return '(For applications)';
    }
  }
  if (typeof socketStatusCodeMappings[code] !== 'undefined') {
    return socketStatusCodeMappings[code];
  }
  return '(Unknown)';
}

export default function websocketMonitorReducer(
  state = {
    type: null,
    timeStamp: null,
    returnValue: null,
    binaryType: null,
    bufferedAmount: null,
    readyState: null,
    readyStateTxt: null,
    url: null,
    code: null,
    codeTxt: null,
    reason: null,
    wasClean: true,
    latencyTimeseries: [],
    latencySorted: [],
    latencyMin: 0,
    latencyMax: 0,
    latencyP95: 0,
    latencyP99: 0,
  },
  action
) {
  switch (action.type) {
    case WEBSOCKET_MONITOR: {
      const { type, timeStamp, target, returnValue } = action.payload;
      const { binaryType, bufferedAmount, readyState, url } = target;

      const commonState = {
        type,
        timeStamp,
        returnValue,
        binaryType,
        bufferedAmount,
        readyState,
        readyStateTxt: socketReadyState[readyState],
        url,
      };

      return {
        ...state,
        ...(type === 'close'
          ? {
              ...commonState,
              code: action.payload.code,
              codeTxt: getStatusCodeString(action.payload.code),
              reason: action.payload.reason,
              wasClean: action.payload.wasClean,
            }
          : {
              ...commonState,
              code: null,
              codeTxt: null,
              reason: null,
              wasClean: true,
            }),
      };
    }
    case LATENCY: {
      const [first, ...rest] = state.latencyTimeseries;
      const timestamp = formatDateToTime(new Date(), false);
      const latency = action.payload;
      const latencyTimeseries =
        state.latencyTimeseries.length < WEBSOCKET_LATENCY_TIMESERIES_SIZE_LIMIT
          ? [
              ...(first == null ? [] : [first]),
              ...rest,
              {
                timestamp,
                latency,
              },
            ]
          : [
              ...rest,
              {
                timestamp,
                latency: action.payload,
              },
            ];

      const latencySorted =
        first == null
          ? [{ timestamp, latency }]
          : insertionSortByObjPropArray(
              [
                ...state.latencySorted.filter(
                  ({ timestamp: ts }) => ts !== first.timestamp
                ),
                {
                  timestamp,
                  latency,
                },
              ],
              'latency'
            );
      return {
        ...state,
        latencyTimeseries,

        latencySorted,

        latencyMin: first == null ? 0 : latencySorted[0].latency,
        latencyMax:
          first == null
            ? 0
            : latencySorted[latencySorted.length - 1].latency || 0,
        latencyP95:
          first == null
            ? 0
            : calcQuartile(
                latencySorted.map((datapoint) => datapoint.latency),
                95
              ),
        latencyP99:
          first == null
            ? 0
            : calcQuartile(
                latencySorted.map((datapoint) => datapoint.latency),
                99
              ),
      };
    }
    default:
      return state;
  }
}
