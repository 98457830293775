import { PAUSE_TRADING, RESUME_TRADING } from '../actions/action_types';

export default function tradingReducers(state = false, action) {
  switch (action.type) {
    case PAUSE_TRADING:
      return true;
    case RESUME_TRADING:
      return false;
    default:
      return state;
  }
}
