import { TOB_UPDATE } from '../actions/action_types';

export default function tobUpdateReducer(state = {}, action) {
  switch (action.type) {
    case TOB_UPDATE: {
      const { i, m, b, a } = action.payload;
      return {
        ...state,
        [i]: {
          ...state[i],
          [m]: { b, a },
        },
      };
    }
    default:
      return state;
  }
}
