import { OrderType } from '~types/common';

export const FWD_CURVE_VER_DEFAULT = 'FWD_CURVE_VER_DEFAULT';
export const FWD_CURVE_VER_DEL_T0 = 'FWD_CURVE_VER_DEL_T0';
export const FWD_CURVE_VER_DEL_T1 = 'FWD_CURVE_VER_DEL_T1';
export const FWD_CURVE_VER_CMP_T0 = 'FWD_CURVE_VER_CMP_T0';
export const FWD_CURVE_VER_CMP_T1 = 'FWD_CURVE_VER_CMP_T1';

export const fwdCurveMap = {
  FWD_CURVE_VER_DEFAULT: 'Default',
  FWD_CURVE_VER_DEL_T0: 'Delivery T0',
  FWD_CURVE_VER_DEL_T1: 'Delivery T1',
  FWD_CURVE_VER_CMP_T0: 'Comp T0',
  FWD_CURVE_VER_CMP_T1: 'Comp T1',
};

export const socketReadyState = {
  0: 'CONNECTING', // Socket has been created. The connection is not yet open.
  1: 'OPEN', // The connection is open and ready to communicate.
  2: 'CLOSING', // The connection is in the process of closing.
  3: 'CLOSED', // The connection is closed or couldn't be opened.
};

export const socketStatusCodeMappings = {
  1000: 'Normal Closure',
  1001: 'Going Away',
  1002: 'Protocol Error',
  1003: 'Unsupported Data',
  1004: '(For future)',
  1005: 'No Status Received',
  1006: 'Abnormal Closure',
  1007: 'Invalid frame payload data',
  1008: 'Policy Violation',
  1009: 'Message too big',
  1010: 'Missing Extension',
  1011: 'Internal Error',
  1012: 'Service Restart',
  1013: 'Try Again Later',
  1014: 'Bad Gateway',
  1015: 'TLS Handshake',
};

export const layoutDomains = ['golden_layout', 'flex_layout'];

export const orderTypeList: OrderType[] = [
  'LIMIT',
  'STOP_MARKET',
  'STOP_LIMIT',
  'OCO',
  'ICEBERG',
  'MAN_OFFSET',

  // following still only show in non-prod + permit allowed
  'MARKET',
  'SLICER',
  'FIXING',
];
