import { CANCEL_ALL_ORDERS } from '../actions/action_types';

export default function cancelAllOrdersReducer(state = false, action) {
  switch (action.type) {
    case CANCEL_ALL_ORDERS:
      return !state;
    default:
      return state;
  }
}
