import { format, isValid, parseISO } from 'date-fns';

// YYYYMMDD to YYYY/MM/DD
export function formatDateWithSlashes(date: string | null): string {
  if (!date || date === '') {
    return '';
  }

  const dateString: string = date.toString();

  if (dateString.length !== 8) {
    return '';
  }

  return (
    `${dateString.slice(0, 4)}/` +
    `${dateString.slice(4, 6)}/` +
    `${dateString.slice(-2)}`
  );
}

export function formatDateWithoutSlashes(date: string | Date): string {
  if (!date) {
    return '';
  }

  const dateToProcess: Date = typeof date === 'string' ? parseISO(date) : date;

  if (!isValid(dateToProcess)) {
    return '';
  }

  return format(dateToProcess, 'yyyyMMdd');
}

// Date Obj to YYYY/MM/DD hh:mm:ss(.ms)
export function formatDateObj(date: Date, withoutMS: boolean): string {
  if (!(date instanceof Date)) {
    return '';
  }

  const year: string = date.getFullYear().toString();
  const month: string = String(date.getMonth() + 1).padStart(2, '0');
  const day: string = String(date.getDate()).padStart(2, '0');
  const hours: string = String(date.getHours()).padStart(2, '0');
  const minutes: string = String(date.getMinutes()).padStart(2, '0');
  const seconds: string = String(date.getSeconds()).padStart(2, '0');
  const milliseconds: string = String(date.getMilliseconds()).padStart(3, '0');

  return withoutMS
    ? `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
    : `${year}/${month}/${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function formatDateToTime(date: Date, withoutMS: boolean): string {
  if (!(date instanceof Date)) {
    return '';
  }

  const hours: string = String(date.getHours()).padStart(2, '0');
  const minutes: string = String(date.getMinutes()).padStart(2, '0');
  const seconds: string = String(date.getSeconds()).padStart(2, '0');
  const milliseconds: string = String(date.getMilliseconds()).padStart(3, '0');

  return withoutMS
    ? `${hours}:${minutes}:${seconds}`
    : `${hours}:${minutes}:${seconds}.${milliseconds}`;
}
