import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import NotificationManager from './NotificationManager';
import Notifications from './Notifications';

const NotificationContainer = ({ enterTimeout, leaveTimeout }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    NotificationManager.addChangeListener(handleStoreChange);

    return () => {
      NotificationManager.removeChangeListener(handleStoreChange);
    };
  }, [handleStoreChange]);

  const handleStoreChange = useCallback((updatedNotifications) => {
    setNotifications(updatedNotifications);
  }, []);

  const handleRequestHide = useCallback((id) => {
    NotificationManager.remove(id);
  }, []);

  return (
    <Notifications
      enterTimeout={enterTimeout}
      leaveTimeout={leaveTimeout}
      notifications={notifications}
      onRequestHide={handleRequestHide}
    />
  );
};

NotificationContainer.propTypes = {
  enterTimeout: PropTypes.number,
  leaveTimeout: PropTypes.number,
};

NotificationContainer.defaultProps = {
  enterTimeout: 400,
  leaveTimeout: 400,
};

export default NotificationContainer;
