import { STP_UPDATE } from '../actions/action_types';

export default function stpReducer(state = [], action) {
  switch (action.type) {
    case STP_UPDATE: {
      const pos = (state || []).findIndex(
        (o) => o.internal_order_id === action.payload.internal_order_id
      );

      if (!state.length || (state.length && pos === -1)) {
        return [...state, action.payload];
      }

      return state;
    }
    default:
      return state;
  }
}
