import { SESSION_SERVICE_STARTUP } from '../actions/action_types';
import utilDateTime from '../utils/date-time';

export default function sessionServiceStartupReducer(
  state = utilDateTime.getEtcDateFromUserTimeZone(new Date()),
  action
) {
  switch (action.type) {
    case SESSION_SERVICE_STARTUP:
      return utilDateTime.getEtcDateFromUserTimeZone(new Date());
    default:
      return state;
  }
}
