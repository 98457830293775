import { SHADOW_FOLLOW } from '../actions/action_types';

export default function shadowUsernameReducer(state = 'all', action) {
  switch (action.type) {
    case SHADOW_FOLLOW:
      return action.payload;
    default:
      return state;
  }
}
