import { IS_POPOUT } from './action_types';

export const reduxPopout = (payload) => (dispatch) =>
  dispatch({
    type: IS_POPOUT,
    payload,
  });

export const setPopouts = (payload) => (dispatch) =>
  dispatch({
    type: 'SET_POPOUTS',
    payload,
  });
