import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { StyledCloseButton, StyledNotification } from './notifications.styles';

const Notification = ({
  timeOut,
  onClick,
  onRequestHide,
  type,
  message,
  title,
  closeButton,
}) => {
  const timer = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    let timerRef = timer.current;

    if (timeOut !== 0) {
      timerRef = setTimeout(requestHide, timeOut);
    }

    return () => {
      if (timerRef) {
        clearTimeout(timerRef);
      }
    };
  }, [timeOut, requestHide]);

  useEffect(() => {
    const ref = containerRef?.current;

    if (ref) {
      ref.addEventListener('click', handleClick);
    }

    return () => {
      ref.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
    requestHide();
  }, [onClick, requestHide]);

  const requestHide = useCallback(() => {
    if (onRequestHide) {
      onRequestHide();
    }
  }, [onRequestHide]);

  const handleCloseButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      onRequestHide();
    },
    [onRequestHide]
  );

  return (
    <StyledNotification className={`notification notification-${type}`}>
      <div ref={containerRef} className="notification-message" role="alert">
        {title && <h4 className="title">{title}</h4>}
        <div className="message">{message}</div>
      </div>
      {closeButton && (
        <StyledCloseButton onClick={handleCloseButtonClick}>
          <CloseIcon />
        </StyledCloseButton>
      )}
    </StyledNotification>
  );
};

Notification.defaultProps = {
  type: 'info',
  title: null,
  message: null,
  timeOut: 5000,
  onClick: () => {},
  onRequestHide: () => {},
  closeButton: false,
};

Notification.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  title: PropTypes.node,
  message: PropTypes.node,
  timeOut: PropTypes.number,
  onClick: PropTypes.func,
  onRequestHide: PropTypes.func,
  closeButton: PropTypes.bool,
};

export default Notification;
