import { EventEmitter } from 'events';
import cuid from 'cuid';

const Constants = {
  CHANGE: 'change',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

class NotificationManager extends EventEmitter {
  constructor() {
    super();
    this.listNotify = [];
  }

  create(notify) {
    const defaultNotify = {
      type: 'info',
      title: null,
      message: null,
      timeOut: 5000,
    };

    this.listNotify = [
      ...this.listNotify,
      {
        ...defaultNotify,
        ...notify,
        id: notify.id ?? cuid(),
      },
    ];

    this.emitChange();
  }

  info(message, title, timeOut, id, closeButton, onClick) {
    this.create({
      id,
      type: Constants.INFO,
      message,
      title,
      timeOut,
      onClick,
      closeButton,
    });
  }

  success(message, title, timeOut, id, closeButton, onClick) {
    this.create({
      id,
      type: Constants.SUCCESS,
      message,
      title,
      timeOut,
      onClick,
      closeButton,
    });
  }

  warning(message, title, timeOut, id, closeButton, onClick) {
    this.create({
      id,
      type: Constants.WARNING,
      message,
      title,
      timeOut,
      onClick,
      closeButton,
    });
  }

  error(message, title, timeOut, id, closeButton, onClick) {
    this.create({
      id,
      type: Constants.ERROR,
      message,
      title,
      timeOut,
      onClick,
      closeButton,
    });
  }

  remove(id) {
    this.listNotify = this.listNotify.filter((n) => id !== n.id);
    this.emitChange();
  }

  removeAll() {
    this.listNotify.length = 0;
    this.emitChange();
  }

  emitChange() {
    this.emit(Constants.CHANGE, this.listNotify);
  }

  addChangeListener(callback) {
    this.addListener(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new NotificationManager();
