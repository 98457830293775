/** Calculate the 'q' quartile of an array of values
 *
 * @arg sortedArr - array of values sorted into ascending order
 * @arg q - percentile to calculate (e.g. 95)
 */
// eslint-disable-next-line import/prefer-default-export
export function calcQuartile(sortedArr, q) {
  // Turn q into a decimal (e.g. 95 becomes 0.95)
  const percentile = q / 100;

  // Work out the position in the array of the percentile point
  const percentilePoint = (sortedArr.length - 1) * percentile;
  const percentilePointFloor = Math.floor(percentilePoint);

  // Work out what we rounded off (if anything)
  const remainder = percentilePoint - percentilePointFloor;

  // See whether that data exists directly
  if (sortedArr[percentilePointFloor + 1] != null) {
    return (
      parseFloat(sortedArr[percentilePointFloor]) +
      remainder *
        (parseFloat(sortedArr[percentilePointFloor + 1]) -
          parseFloat(sortedArr[percentilePointFloor]))
    );
  }
  return parseFloat(sortedArr[percentilePointFloor]);
}
