import {
  SESSION_STATUS,
  SESSION_SERVICE_STARTUP,
} from '../actions/action_types';
import utilDateTime from '../utils/date-time';

const initTimestamp = utilDateTime.getEtcDateFromUserTimeZone(new Date());
const INITIAL_STATUS = {
  isConnected: false,
  timestamp: initTimestamp,
  lastChanged: initTimestamp,
};

export default function sessionStatusReducer(state = INITIAL_STATUS, action) {
  const dateEtcNow = utilDateTime.getEtcDateFromUserTimeZone(new Date());
  switch (action.type) {
    case SESSION_STATUS:
      return {
        ...state,
        isConnected: Boolean(action.payload.sessionServiceStatus),
        timestamp: dateEtcNow,
        lastChanged:
          state.isConnected !== Boolean(action.payload.sessionServiceStatus)
            ? dateEtcNow
            : state.lastChanged,
      };
    case SESSION_SERVICE_STARTUP:
      return {
        ...state,
        isConnected: false,
        lastChanged: dateEtcNow,
      };
    default:
      return state;
  }
}
