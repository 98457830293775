import cloneDeep from 'lodash/cloneDeep';
import { addBreadcrumb, setUser } from '@sentry/react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getInitialLayout = (data: any) => {
  const layout = data?.flex_layout;
  if (layout && !window.location.pathname.includes('widget')) {
    const layoutData = cloneDeep(layout?.[window.APP_NAME]);

    // openPopouts already tracked as own Breadcrumbs
    // keep data small
    if (layoutData?.openPopouts) {
      delete layoutData.openPopouts;
    }

    // avoid console log at the end user browser
    // log as a breabcrumb of dashboard transaction in the sentry
    // don't create a sepearted log
    if (layoutData) {
      // transaction only has ip info, no user info
      // so set user info to easy to find the transaction
      const uname = sessionStorage.getItem('global_username');
      const orgId = sessionStorage.getItem('global_org_id');
      const orgName = sessionStorage.getItem('global_organization');
      setUser({
        id: `username:${uname} | org-id:${orgId} | org-name:${orgName}`,
        username: uname,
      });

      const msg = JSON.stringify(layoutData);

      addBreadcrumb({
        type: 'debug',
        category: 'initial.layout',
        message: msg,
        level: 'debug',
      });
    }
  }
};

export default getInitialLayout;
