import { CREDIT_CHECK_UPDATE, INSTRUMENTS_CREDIT_CHECK } from './action_types';

export const reduxCreditCheck = (payload) => (dispatch) =>
  dispatch({
    type: CREDIT_CHECK_UPDATE,
    payload: payload?.data || payload,
  });

export const reduxInstrumentsCreditCheck = (payload) => (dispatch) =>
  dispatch({
    type: INSTRUMENTS_CREDIT_CHECK,
    payload,
  });
