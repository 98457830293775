import {
  START_RFQ_RFS_SUB,
  START_RFQ_RFS_SUB_ALL,
  REMOVE_RFQ_RFS_SUB,
  REMOVE_RFQ_RFS_SUB_ALL,
} from './action_types';

export const startRfqRfsSubscription =
  ({
    streamType,
    instrumentId,
    instrumentPair,
    date,
    dateFar,
    datePublish,
    tenor,
    tenorFar,
    clientRequestId,
    instrumentIdUnsub,
    dateUnsub,
    dateFarUnsub,
    tenorUnsub,
    tenorFarUnsub,
    uuid: widgetRef,
    amount,
    amountUnsub,
    fwdCurveVersion,
    fwdCurveVersionUnsub,
    side = null,
    isRfq = false,
    parentInternalRequestId = null,
  }) =>
  (dispatch) =>
    dispatch({
      type: START_RFQ_RFS_SUB,
      payload: {
        streamType,
        instrumentId,
        instrumentPair,
        tenor,
        tenorFar,
        date,
        dateFar,
        datePublish,
        clientRequestId,
        instrumentIdUnsub,
        dateUnsub,
        dateFarUnsub,
        tenorUnsub,
        tenorFarUnsub,
        widgetRef,
        amount,
        amountUnsub,
        fwdCurveVersion,
        fwdCurveVersionUnsub,
        side,
        isRfq,
        parentInternalRequestId,
      },
    });

export const startRfqRfsSubscriptionAll = (payload) => (dispatch) => {
  const dto = Array.isArray(payload)
    ? payload.map(
        ({
          rfsType,
          clientRequestId,
          instrumentId,
          instrumentPair,
          date,
          dateFar,
          tenor,
          tenorFar,
          uuid: widgetRef,
          amount,
          fwdCurveVersion,
        }) => ({
          rfsType,
          clientRequestId,
          instrumentId,
          instrumentPair,
          date,
          dateFar,
          tenor,
          tenorFar,
          widgetRef,
          amount,
          fwdCurveVersion,
        })
      )
    : [];
  return dispatch({
    type: START_RFQ_RFS_SUB_ALL,
    payload: dto,
  });
};

export const stopRfqRfsSubscription =
  ({
    streamType,
    instrumentId,
    instrumentPair,
    date,
    dateFar,
    tenor,
    tenorFar,
    clientRequestId,
    uuid: widgetRef,
    amount,
    fwdCurveVersion,
    isRfq = false,
  }) =>
  (dispatch) =>
    dispatch({
      type: REMOVE_RFQ_RFS_SUB,
      payload: {
        streamType,
        instrumentId,
        instrumentPair,
        tenor,
        tenorFar,
        date,
        dateFar,
        clientRequestId,
        widgetRef,
        amount,
        fwdCurveVersion,
        isRfq,
      },
    });

export const stopRfqRfsSubscriptionAll = () => (dispatch) =>
  dispatch({
    type: REMOVE_RFQ_RFS_SUB_ALL,
  });
