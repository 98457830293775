export function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export function deepMerge(...args) {
  const target = {};

  // Merge the object into the target object
  const merger = (obj) => {
    if (obj) {
      Object.entries(obj).forEach((entry) => {
        const [key, value] = entry;

        if (Object.prototype.toString.call(value) === '[object Object]') {
          // If we're doing a deep merge and the property is an object
          target[key] = deepMerge(target[key], obj[key]);
        } else {
          // Otherwise, do a regular merge
          target[key] = obj[key];
        }
      });
    }
  };

  // Loop through each object and conduct a merge
  for (let i = 0; i < args.length; i += 1) {
    merger(args[i]);
  }

  return target;
}

export function isObject(variable) {
  return Object.prototype.toString.call(variable) === '[object Object]';
}
