import { BOOK_UPDATE, RFQ_UPDATE, TOB_UPDATE } from './action_types';

export const reduxBookUpdate = (payload) => (dispatch) =>
  dispatch({
    type: BOOK_UPDATE,
    payload,
  });

export const reduxRfqUpdate = (payload) => (dispatch) =>
  dispatch({
    type: RFQ_UPDATE,
    payload,
  });

export const reduxTobUpdate = (payload) => (dispatch) =>
  dispatch({
    type: TOB_UPDATE,
    payload,
  });
