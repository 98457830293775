import { LATENCY } from '../actions/action_types';

export default function latencyReducer(state = 0, action) {
  switch (action.type) {
    case LATENCY:
      return action.payload;
    default:
      return state;
  }
}
