import { resetSetting } from '../utils/settings';

import { SET_LAYOUT } from '../actions/action_types';

export default function layoutReducer(state = '', action) {
  switch (action.type) {
    case SET_LAYOUT:
      if (action.payload === 'default')
        resetSetting(window.APP_NAME, 'flex_layout');
      return action.payload;
    default:
      return state;
  }
}
