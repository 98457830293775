const multiCurrencyDefaultSettings = {
  edgefx: {
    double_click: false,
    show_presets: true,
    show_bidoffer: true,
    show_position: false,
    show_joins: true,
    show_mids: true,
    show_ladder: true,
    toogle_hot_presets: false,
    ocoAutomatic: false,
  },
  default: {
    double_click: true,
    show_presets: true,
    show_bidoffer: true,
    show_position: false,
    show_joins: false,
    show_mids: false,
    show_ladder: false,
    toogle_hot_presets: false,
    ocoAutomatic: false,
  },
};

export default multiCurrencyDefaultSettings;
