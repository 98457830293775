import { COMPLETED_ORDER } from '../actions/action_types';

export default function completedOrder(state = false, action) {
  switch (action.type) {
    case COMPLETED_ORDER:
      return action.payload;
    default:
      return state;
  }
}
