import { ORDER_UPDATE } from '../actions/action_types';

export default function ordersUpdate(state = {}, action) {
  switch (action.type) {
    case ORDER_UPDATE:
      return action?.payload;
    default:
      return state;
  }
}
