import React from 'react';

import Logo from '../components/auth/Logo';

const Desktop = () => {
  const os = 'win';
  const ver = '2.0.0'; // TODO: make dynamic - get from api
  const installerFileName = `${window.location.host}-v${ver}-${window.ENV}-desktop-installer`;
  const cfgUrl = `${window.location.protocol}//${window.location.host}/trader-rest-service/v1/fin-manifest/${window.location.host}`;
  const url = `https://install.openfin.co/download/?config=${cfgUrl}&os=${os}&fileName=${installerFileName}`;

  return (
    <main data-testid="desktop:desktop" className="main-desktop">
      <div className="desktop-wrap">
        <div className="desktop-body">
          <Logo />
          <div className="cta-win">
            <a href={url}>
              <img src="/static/images/download-for-windows-btn.png" alt="" />
            </a>
          </div>
          <div className="requirement-highlights">
            {
              // TODO: source data from api endpoint
            }
            <ul>
              <li>Version {ver}</li>
              <li>Windows 64bit</li>
              <li>1.5 MB</li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Desktop;
