import {
  OPENMODAL,
  MODAL_MODIFY_ORDER,
  MODAL_SETTINGS,
  MODAL_CLOSE,
  MODAL_PASSWORD_CHANGE,
  UPDATEMODAL,
  BOOK_UPDATE,
} from './action_types';

export const openLimitModal =
  (payload = {}) =>
  (dispatch) =>
    dispatch({
      type: OPENMODAL,
      payload: {
        blur: payload.blur,
        modal: payload.modal,
        symbol: payload.symbol,
        price: payload.price,
        full_symbol_name: payload.full_symbol_name,
        slippage: payload.slippage,
        expireTime: payload.expire_time,
        max_amount_per_order: payload.max_amount_per_order,
        term_currency: payload.term_currency,
        instrument_id:
          payload.instrument_id != null
            ? payload.instrument_id.toString()
            : payload.instrument_id,
        ndf: payload.ndf,
        settle_date: payload.settle_date,
        fixing_date: payload.fixing_date,
        tenor: payload.tenor,
        doubleWidget: payload.doubleWidget,
      },
    });

export const reduxModalUpdate = (payload) => (dispatch) =>
  dispatch({
    type: UPDATEMODAL,
    payload,
  });

export const reduxBookUpdate = (payload) => (dispatch) =>
  dispatch({
    type: BOOK_UPDATE,
    payload,
  });

/* WILL TAKE OVER OPEN LIMIT MODAL IN THE FUTURE */
export const reduxModal =
  (payload = {}) =>
  (dispatch) => {
    const { modal_name: modalName, order, data } = payload;
    if (modalName === 'order_modify') {
      dispatch({
        type: MODAL_MODIFY_ORDER,
        payload: {
          modal_name: modalName,
          order,
          max_amount_per_order: 200000000,
        },
      });
    }

    if (modalName === 'modal_settings') {
      dispatch({
        type: MODAL_SETTINGS,
        payload: {
          modal_name: modalName,
          data,
        },
      });
    }

    if (modalName === 'modal_password_change') {
      dispatch({
        type: MODAL_PASSWORD_CHANGE,
        payload: {
          modal_name: modalName,
        },
      });
    }

    dispatch({
      type: MODAL_CLOSE,
      payload: {
        modal_name: modalName,
      },
    });
  };
