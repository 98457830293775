// eslint-disable-next-line import/prefer-default-export
export function insertionSortByObjPropArray(inputArr, propName) {
  for (let i = 1; i < inputArr.length; i += 1) {
    let j = i;
    while (j > 0 && inputArr[j][propName] < inputArr[j - 1][propName]) {
      [inputArr[j - 1], inputArr[j]] = [inputArr[j], inputArr[j - 1]];
      j -= 1;
    }
  }
  return inputArr;
}
