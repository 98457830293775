const ymdDashT24H = "yyyy-MM-dd'T'HH:mm";
const ymdSlash24FT = 'yyyy/MM/dd HH:mm:ss';
const ymdSlash24FTMSec = 'yyyy/MM/dd HH:mm:ss SSS';
const mdySlash12 = 'MM/dd/yyyy hh:mm a';
const ymdDash12 = 'yyyy-MM-dd h:mm a';
const ymd = 'yyyyMMdd';
const ymdDash = 'yyyy-MM-dd';
const ymdSlash = 'yyyy/MM/dd';
const time24 = 'HH:mm:ss';
const resetTimeDefault = '17:00';

export {
  ymdDashT24H,
  ymdSlash24FT,
  ymdSlash24FTMSec,
  mdySlash12,
  ymdDash12,
  ymd,
  ymdDash,
  ymdSlash,
  time24,
  resetTimeDefault,
};
