import {
  POST_ASSET,
  UPDATE_ASSET,
  UPDATE_ASSET_RFQ,
  UPDATE_ASSET_PAIR_WIDGET,
} from './action_types';

export const reduxPostAsset = (payload) => (dispatch) => {
  dispatch({
    type: POST_ASSET,
    payload,
  });
};

export const reduxUpdateAsset = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_ASSET,
    payload,
  });
};

export const reduxUpdateAssetRfq = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_ASSET_RFQ,
    payload,
  });
};

export const reduxUpdateInstrumentWidget = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_ASSET_PAIR_WIDGET,
    payload,
  });
};
