import {
  ADD_RFQ_SUB,
  ADD_RFQ_SUB_LIST,
  RFQ_DEAL_CLICKED,
} from '../actions/action_types';

const initialState = { list: [], dealClicked: null };

const rfqSubscriptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RFQ_SUB_LIST: {
      return { ...state, list: action.payload };
    }
    case ADD_RFQ_SUB: {
      const newUpdate = action.payload;
      const exists = state.list.find(
        ({ internal_request_id: id }) => newUpdate.internal_request_id === id
      );

      if (exists) {
        const newList = state.list.map((item) => {
          if (item.internal_request_id === newUpdate.internal_request_id) {
            return newUpdate;
          }
          return item;
        });

        return { ...state, list: newList };
      }

      return { ...state, list: [...state.list, newUpdate] };
    }
    case RFQ_DEAL_CLICKED: {
      return { ...state, dealClicked: action.payload };
    }
    default:
      return state;
  }
};

export default rfqSubscriptionsReducer;
