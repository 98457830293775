import { useCallback, useEffect, useState, useRef } from 'react';
import EventEmitter from 'events';
import { useHistory } from 'react-router-dom';
import frontLogger from '../utils/log';
import { wsOrigin } from '../configs/apiOrigin';
import logger from '../utils/logger';

const log = frontLogger('websocket');
// TODO: HEARTBEAT!

// System Status
// systemStatus({ wsStatus: false });
// systemStatus({ webSocketStatus: Socket.connected });
// const systemStatus = (data) => {
//   // if (ENV === 'development') console.log('[DASHBOARD] System Status', data);
//   props.reduxSetSystemStatus(data);
// };

export const useWebsocket = ({ reduxWebsocketMonitor, ready }) => {
  const history = useHistory();
  const [socket, setSocket] = useState(null);
  const wsAuthenticated = useRef(false);
  const socketRef = useRef(null);
  const [emitter] = useState(new EventEmitter());

  const listenToEmitters = useCallback(() => {
    emitter.on('msg', (e) => {
      const { msgType, data = {} } = e;
      const ws = socketRef.current;

      if (ws && ws.readyState === 1 && wsAuthenticated.current) {
        ws.send(
          JSON.stringify({
            body: {
              msg_type: msgType,
              data,
            },
          })
        );
      }
    });
  }, [emitter]);

  useEffect(() => {
    console.log('listening to events from the UI to the Websocket');
    listenToEmitters();
  }, [listenToEmitters]);

  // Websocket events that dispatch into actions
  useEffect(() => {
    if (!socket && ready) {
      const webSocket = new WebSocket(wsOrigin());

      // if there is already a socket, add the event listenners
      webSocket.addEventListener('open', (ev) => {
        const { type, target, timeStamp } = ev;
        // eslint-disable-next-line no-console
        console.log(
          `websocket event: 'open' type: '${type}' timeStamp: ${timeStamp}`
        );
        const {
          url,
          readyState,
          protocol,
          binaryType,
          bufferedAmount,
          extensions,
        } = target;
        // eslint-disable-next-line no-console
        console.log(
          `websocket event: 'open' target url: '${url}' readyState: ${readyState} binaryType: '${binaryType}' bufferedAmount: ${bufferedAmount} protocol: '${protocol}' extensions: '${extensions}'`
        );

        reduxWebsocketMonitor(ev);
        log.dev('websocket has connected to the server');

        webSocket.send(
          JSON.stringify({
            body: {
              msg_type: 'auth',
              data: {
                token: sessionStorage.getItem('token'),
              },
            },
          })
        );
        socketRef.current = webSocket;
      });

      webSocket.addEventListener('error', (ev) => {
        // eslint-disable-next-line no-console
        console.log('error on socket listeners');

        const { type, target, timeStamp } = ev;
        // eslint-disable-next-line no-console
        console.log(
          `websocket event: 'error' type: '${type}' timeStamp: ${timeStamp}`
        );
        const {
          url,
          readyState,
          protocol,
          binaryType,
          bufferedAmount,
          extensions,
        } = target;
        // eslint-disable-next-line no-console
        console.log(
          `websocket event: 'error' target url: '${url}' readyState: ${readyState} binaryType: '${binaryType}' bufferedAmount: ${bufferedAmount} protocol: '${protocol}' extensions: '${extensions}'`
        );
        reduxWebsocketMonitor(ev);
      });

      webSocket.addEventListener('close', (ev) => {
        const { type, target, timeStamp, code, wasClean } = ev;
        // eslint-disable-next-line no-console
        console.log(
          `websocket event: 'close' type: '${type}' timeStamp: ${timeStamp} code: ${code} wasClean: '${wasClean}'`
        );
        const {
          url,
          readyState,
          protocol,
          binaryType,
          bufferedAmount,
          extensions,
        } = target;

        reduxWebsocketMonitor(ev);
        if (!wasClean || code !== 1000) {
          // eslint-disable-next-line no-console
          console.log(
            `websocket event: 'error' target url: '${url}' readyState: ${readyState} binaryType: '${binaryType}' bufferedAmount: ${bufferedAmount} protocol: '${protocol}' extensions: '${extensions}'`
          );

          logger.logWarning('websocket closed: abnormal or not clean', {
            isLogIfHidden: false,
          });
        }
        setTimeout(() => {
          // Remove websocket state to start reconnection
          setSocket(null);
          wsAuthenticated.current = false;
          socketRef.current = null;
        }, 5000);
      });

      webSocket.addEventListener('message', (e) => {
        const payload = JSON.parse(e.data);

        const { msg_type: msgType, data, ack } = payload.body;
        if (ack) {
          webSocket.send(
            JSON.stringify({
              body: {
                msg_type: 'ack',
                data: ack,
              },
            })
          );
        }
        if (msgType === 'auth-result') {
          if (data.data === 'fail') {
            history.push('/');
          }
          wsAuthenticated.current = true;
        }
        emitter.emit(msgType, data);
      });

      log.dev('websocket created successful');
      setSocket(webSocket);
      window.socket = webSocket;
    }
  }, [socket, emitter, history, ready]);

  return emitter;
};

export default useWebsocket;
